.OverFooter {
  background-size: cover;
  padding-top: calc(45px + (100vw - 360px) / 1560 * 35);
  padding-bottom: calc(45px + (100vw - 360px) / 1560 * 35);
  text-align: center;
}

@media all and (min-width: 1920px) {
  .OverFooter {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.OverFooter p {
  font-size: 24px;
  color: #fff;
}

.OverFooter .Container > * {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}
