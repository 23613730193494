.Footer {
  font-size: 18px;
  text-align: center;
}

.Footer .Container > * {
  padding-top: 2em;
  padding-bottom: 2em;
}

.Footer img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 60px;
}

.Footer .Container p {
  max-width: 100%;
  font-size: 12px;
}

.Footer a {
  color: inherit;
}

@media all and (min-width: 1024px) {
  .Footer img {
    height: calc(60px + (100vw - 1024px) / 256 * 12);
  }
}

@media all and (min-width: 1280px) {
  .Footer img {
    height: 72px;
  }
}
