.Button > * {
  font-family: inherit;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  text-decoration: none;
  text-transform: uppercase;
  color: inherit;
  margin: 0;
  padding: 0 0 4px 0;
  position: relative;
}

.Button:hover > * {
  color: #568fab;
}

.Button > button {
  background-color: transparent;
  border: 0px none;
  cursor: pointer;
}

.Button > *::after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 1px;
  background-color: currentColor;
  transition: height 100ms ease;
}

.Button > *:hover::after {
  height: 3px;
}

.Button > *:active {
  color: var(--color-primary-medium);
}
