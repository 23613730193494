h2.Heading {
  font-weight: bold;
  font-size: calc(20px + (100vw - 360px) / 408 * 10);
  max-width: 480px;
}

h3.Heading {
  display: inline-block;
  font-weight: normal;
  padding: 0.33em;
  color: #fff;
  font-size: calc(25px + (100vw - 360px) / 408 * 5);
}

h3.Heading.negative {
  background-color: #fff;
  color: var(--color-primary-medium);
}

h4.Heading {
  font-weight: bold;
  font-size: calc(15px + (100vw - 360px) / 408 * 3);
  max-width: 480px;
}

@media all and (min-width: 768px) {
  h2.Heading {
    font-size: 30px;
  }

  h3.Heading {
    font-size: 30px;
  }

  h4.Heading {
    font-size: 18px;
  }
}

@media all and (min-width: 533px) {
  h2.Heading,
  h4.Heading {
    margin-left: auto;
    margin-right: auto;
  }
}
