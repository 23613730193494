.ProductsResult .content {
  text-align: center;
}

.ProductsResult .content h2 {
  font-family: 'Holiday';
  font-size: 50px;
  color: var(--color-twilight-blue);
  font-weight: normal;
  margin-top: 0;
  padding-top: 0.83em;
}

.ProductsResult .content img {
  height: 120px;
  margin-left: auto;
  margin-right: auto;
}

.ProductsResult .content h3 {
  font-size: 35px;
  color: var(--color-twilight-blue);
}

.ProductsResult .content p:first-of-type {
  padding-bottom: 0;
}

.ProductsResult .content p + p {
  padding-top: 0;
}

.ProductsResult .products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.ProductsResult .products .product {
  width: 50%;
  text-align: center;
}

.ProductsResult .products .product a {
  color: inherit;
  text-decoration: none;
}

@media all and (min-width: 720px) {
  .ProductsResult .products .product {
    width: 25%;
  }
}

.ProductsResult .products .product h3 {
  text-transform: uppercase;
  font-weight: normal;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  box-sizing: border-box;
  padding-left: 1em;
  padding-right: 1em;
}

.ProductsResult .products .product .number {
  display: block;
  font-size: 1.3em;
  border-top: 1px solid #000;
  padding-top: 0.5em;
  margin-bottom: 0.5em;
}

.ProductsResult .links {
  padding-top: 2.5em;
  padding-bottom: 2.5em;
  text-align: center;
}

.ProductsResult .links p:first-child {
  padding-bottom: 0;
}

.products-result-tip {
  padding-top: 4em;
  padding-bottom: 3.5em;
  text-align: center;
  color: #fff;
  background-size: cover;
}

.products-result-tip h2 {
  font-family: 'Holiday';
  font-weight: normal;
  font-size: 120px;
  margin-top: 0;
  margin-bottom: 40px;
  line-height: 0.56;
}
