.Menu {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
  background-color: #fff;
}

.Menu .Container {
  height: 50px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Menu .Container > p {
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

.MenuContent + * {
  margin-top: 50px;
}

.MenuContent {
  position: fixed;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms ease, visibility 300ms;
  background-color: #fff;
}

@supports (
  (-webkit-backdrop-filter: blur(45px) brightness(2)) or
    (backdrop-filter: blur(45px) brightness(2))
) {
  .MenuContent {
    background-color: transparent;
    backdrop-filter: blur(45px) brightness(1.5);
  }
}

.MenuContent.visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 300ms ease;
}

.MenuContent .content ul {
  list-style-type: none;
  padding: 0;
}

.Menu .content li,
.MenuContent .content li {
  min-height: 48px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.MenuContent .content li + li {
  margin-top: 24px;
}

.MenuContent .content li img {
  margin-left: auto;
  margin-right: auto;
}

.Menu .content li span,
.MenuContent .content li span {
  display: inline-block;
  color: var(--color-grey-dark);
  margin-top: 0.5em;
  cursor: pointer;
}

.Menu .content li a,
.MenuContent .content li a {
  text-decoration: none;
}

@media all and (min-width: 768px) {
  .MenuContent + * {
    margin-top: 70px;
  }

  .Menu .Container {
    height: 70px;
  }

  .Menu .logo {
    height: 50px;
  }

  .Menu .content ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
  }

  .Menu .content li {
    display: flex;
    align-items: center;
  }

  .Menu .content li + li {
    margin-top: 0;
    margin-left: 2.5em;
  }

  .Menu .content li a {
    display: flex;
    align-items: center;
  }

  .Menu .content li span {
    margin-top: 0;
    font-size: 12px;
    margin-left: 1em;
  }
}

@media all and (min-width: 1024px) {
  .Menu + * {
    margin-top: 80px;
  }

  .Menu .Container {
    height: 80px;
  }
}

@media all and (min-width: 2048px) {
  .Menu .Container {
    width: 95vw;
  }
}
