@font-face {
  font-family: 'Panton';
  src: url('./fonts/Panton-Regular.woff2') format('woff2'),
    url('./fonts/Panton-Regular.woff') format('woff'),
    url('./fonts/Panton-Regular.otf') format('opentype');

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Panton';
  src: url('./fonts/Panton-RegularItalic.woff2') format('woff2'),
    url('./fonts/Panton-RegularItalic.woff') format('woff'),
    url('./fonts/Panton-RegularItalic.otf') format('opentype');

  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Panton';
  src: url('./fonts/Panton-Bold.woff2') format('woff2'),
    url('./fonts/Panton-Bold.woff') format('woff'),
    url('./fonts/Panton-Bold.otf') format('opentype');

  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Panton';
  src: url('./fonts/Panton-BoldItalic.woff2') format('woff2'),
    url('./fonts/Panton-BoldItalic.woff') format('woff'),
    url('./fonts/Panton-BoldItalic.otf') format('opentype');

  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Panton';
  src: url('./fonts/Panton-ExtraBold.woff2') format('woff2'),
    url('./fonts/Panton-ExtraBold.woff') format('woff'),
    url('./fonts/Panton-ExtraBold.otf') format('opentype');

  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Holiday';
  src: url('./fonts/Holiday2-Regular.woff2') format('woff2'),
    url('./fonts/Holiday2-Regular.woff') format('woff'),
    url('./fonts/Holiday2-Regular.ttf') format('ttf'),
    url('./fonts/Holiday2-Regular.otf') format('opentype');

  font-weight: normal;
  font-style: normal;
}

:root {
  --color-grey-dark: #575656;
  --color-grey-light: #ededed;
  --color-twilight-blue: #0d5f7d;
}

html {
  -webkit-font-smoothing: antialiased;
  font-family: 'Panton', sans-serif;
  font-size: calc(15px + (100vw - 360px) / 408 * 3);
  line-height: 1.2;
  color: var(--color-grey-dark);
}

html,
body {
  margin: 0;
  padding: 0;
}

img {
  display: block;
  max-width: 100%;
}

p {
  max-width: 480px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 1em;
  padding-bottom: 1.5em;
}

.ceramol {
  font-weight: 900;
}

@media all and (min-width: 768px) {
  html {
    font-size: 18px;
  }
}

@media all and (min-width: 533px) {
  p {
    margin-left: auto;
    margin-right: auto;
  }
}
