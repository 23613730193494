.SlideDownIcon {
  display: block;
  width: 100%;
  background-color: transparent;
  border: none;
  min-width: 48px;
  min-height: 48px;
  margin: 1px 0 0 0;
  padding: 0;
  cursor: pointer;
  transition: transform 200ms ease;
}

.SlideDownIcon .icon {
  display: block;
  width: 1em;
  height: 1em;
  border-style: solid;
  border-width: 1px;
  transform-origin: center;
  transform: translate3d(0, -4px, 0) rotate(45deg);
  margin-left: auto;
  margin-right: auto;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
}

.SlideDownIcon.open {
  transform: rotate(180deg);
}
